import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { SEO, HTML } from '../components/index'
import { media } from '@caldera-digital/theme'

const ImageGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 2rem;
    width: calc(50% - 4rem);
    object-fit: contain;
  }

  ${media.forSmallOnly`
    flex-direction: column;

    img {
      margin: 1rem;
      width: calc(70% - 2rem);
      object-fit: contain;
    }
  `}
`

const ImageGroup = ({ images = [] }) => {
  return (
    <ImageGroupContainer>
      {images.map(({ image, alt }, index) => (
        <img key={index} src={image} alt={alt} />
      ))}
    </ImageGroupContainer>
  )
}

const AboutPage = ({
  data: {
    pagesYaml: { cover, intro },
  },
}) => (
  <Layout heroImage={cover}>
    <Helmet title={`About | ${config.siteTitle}`} />
    <SEO />
    <h1>About the Making of Kate</h1>
    <p>
      Hi, I’m Kate. I graduated from Longwood University in 2014 with an English
      degree (what do you do with an English degree). While attending college, I
      was able to spend a semester studying abroad in Plymouth, England. This
      gave me ample time to meander through cities like Oxford, London, and
      Bath. I also finally got to visit Ireland, where my family is from, and
      traversed gorgeous countryside, kissed the Blarney stone, and saw enough
      sheep to last a lifetime..
    </p>
    <p>
      I spent my final month overseas backpacking with three friends, making our
      way haphazardly through Spain, France, Germany, Italy, and Greece. Once I
      graduated, I spent my first two years out of college working in Virginia.
      But it was no use. Cubical life, and NOVA traffic, were beginning to make
      me feel a little bit-stir crazy. I realized I wanted to see more of the
      world, and that all I had to do was jump.
    </p>
    <ImageGroup
      images={[
        {
          image:
            'https://res.cloudinary.com/the-making-of-kate/image/upload/v1573878610/katie1_nvdocl.jpg',
          alt: 'Kate outside of cool building',
        },
        {
          image:
            'https://res.cloudinary.com/the-making-of-kate/image/upload/v1573878610/katie2_yvqxkm.jpg',
          alt: 'Kate riding horse',
        },
      ]}
    />
    <p>
      On a whim, I applied for a program through the Spanish Government that
      would take me overseas to live and teach for a year. I got in. And so, I
      spent a year teaching English in La Rioja, Spain through the Auxiliares de
      Conversación program! Which led to more travel, more adventure, and the
      feeding of a passion that has only grown despite all I’ve done. The Making
      of Kate was born during my time in Spain as a place to capture stories,
      share my photos, and stay connected with family and friends.
    </p>
    <p>
      I’ve been lucky enough to travel to some amazing places, and have met some
      great people along the way. I love a good story, and find that the best
      kind of adventures are misadventures, and the greatest sort of laughter is
      laughing at yourself.
    </p>
    <p>
      I’m currently working remote and trying to plan my next trip. Any
      suggestions?
    </p>
    <p>
      Feel free to follow along as I try to make a few more mistakes, drink good
      wine, share some stories, and continue to check off my travel list!
    </p>
    <ImageGroup
      images={[
        {
          image:
            'https://res.cloudinary.com/the-making-of-kate/image/upload/v1573878610/katie3_o7c4gw.jpg',
          alt: 'Kate at the ocean',
        },
        {
          image:
            'https://res.cloudinary.com/the-making-of-kate/image/upload/v1573878610/katie4_brfpg8.jpg',
          alt: 'Kate in a hot spring',
        },
      ]}
    />
    <HTML html={intro} />
  </Layout>
)

export const aboutQuery = graphql`
  query AboutQuery {
    pagesYaml(id: { eq: "about" }) {
      intro
      id
      cover
    }
  }
`

export default AboutPage
